.featured-galleries {
  &__container {
    @apply container mx-auto flex flex-col align-middle mb-20 gap-14;
  }

  &__header {
    @apply flex flex-col;

    @screen md {
      @apply flex-row;
    }

    &__title {
      @apply text-base-50 font-semibold text-6xl leading-tighter uppercase;

      @screen md {
        @apply text-7xl;
      }
    }

    &__subtitle {
      @apply pt-1 block font-sans font-normal text-2xl normal-case;

      @screen md {
        @apply text-3xl;
      }
    }

    &__link {
      @apply text-base-50 font-sans font-normal text-xl uppercase mt-2;

      @screen md {
        @apply text-2xl ml-auto mt-0;
      }
    }
  }

  &__list {
    @apply grid gap-6;
    grid-template-columns: repeat(1, 1fr);


    @screen md {
      grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
    }
  }
}

