.faq {
  &__container {
    @apply container mx-auto mb-24;
  }

  &__title {
    @apply uppercase font-semibold text-6xl text-base-100 leading-tighter border-b border-base-300 pb-3
      mb-10;
  }

  &__question-container {
    @apply text-base-100 bg-gradient-to-b from-brand-500 to-base-300 bg-base-300 bg-no-repeat pt-px px-px
      transition-[background-position];

    background-position: 0 -300px;

    &:last-of-type {
      @apply pb-px;
    }

    &_open {
      background-position: 0;
    }
  }

  &__question-title {
    @apply flex justify-between items-center gap-6 uppercase text-2xl leading-tight font-bold bg-base-800 py-5 px-6 transition-colors
      cursor-pointer;

    @screen lg {
      @apply text-4xl
    }

    &:hover,
    &:focus {
      @apply text-brand-500;
    }
  }

  &__question-icon {
    @apply text-4xl self-start;

    @screen lg {
      @apply text-6xl
    }
  }

  &__question-answer {
    @apply bg-base-800 pr-16 pl-6 pb-0 max-h-0 text-xl font-normal overflow-hidden transition-all;

    @screen lg {
      @apply text-2xl
    }

    &_open {
      @apply pb-5 max-h-96;
    }

    &__para {
      @apply mb-2;
    }
  }

  &__link {
    @apply text-brand-500;

    &:hover {
      @apply text-white;
    }
  }
}
