.featured-organizer-card {
  $parentBlock: &;

  &__card {
    @apply border-solid border border-base-300 bg-base-800 flex flex-col relative transition-colors;
  }

  &__banner {
    @apply flex border-b border-base-300 justify-center py-3 transition-colors bg-center bg-cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), var(--bannerUrl);

    @screen md {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), var(--bannerUrl);
    }
  }

  &__avatarWrapper {
    @apply overflow-hidden w-20 h-20 object-cover aspect-square rounded-full border border-base-300 bg-base-300
      transition-transform overflow-hidden;

    @screen md {
      @apply w-32 h-32;
    }
  }

  &__avatar {
    @apply h-full w-full;
  }

  &__organizer-name {
    @apply grow grid place-items-center font-bold uppercase text-xl py-3 px-4 text-center;

    @screen md {
      @apply text-2xl;
    }
  }

  &__link {
    @apply absolute inset-0 opacity-0 z-featured-organizer-card-link;
  }

  &__card:hover, &__card:focus {
    @apply border-base-100;

    #{$parentBlock}__banner{
      @apply border-base-100;
    }

    #{$parentBlock}__avatarWrapper {
      @apply scale-105;
    }
  }
}
