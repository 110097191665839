.featured-gallery-card {
  &__container {
    @apply flex w-full items-center py-4 px-4 gap-x-4 bg-gradient-to-l from-base-550 to-base-800/70
      border border-base-400;

    @screen xl {
      @apply py-6 px-6;
    }

    &:hover {
      @apply transition-colors border-base-100;
    }
  }

  &__avatar {
    @apply rounded-full w-24 h-24 border border-base-100 bg-base-100 flex-shrink-0 flex-grow-0;

    @screen xl {
      @apply w-32 h-32;
    }
  }

  &__icon {
    @apply text-base-100 ml-auto;
  }

  &__title {
    @apply font-semibold text-base-100 text-2xl uppercase;

    @screen xl {
      @apply text-4xl leading-tighter;
    }
  }
}
