.featured-banner {
  &__background {
    @apply w-screen pt-16 pb-10 mb-[calc(min(340px,100vw)+theme('spacing.10')+theme('spacing.36'))];
    background-image:
      linear-gradient(to bottom, theme('colors.base.800'), transparent, theme('colors.base.800')),
      url('/img/backgrounds/banner/tile-mobile.svg');

    @screen lg {
      @apply mb-28;
      background-image:
        linear-gradient(to bottom, theme('colors.base.800'), transparent, theme('colors.base.800')),
        url('/img/backgrounds/banner/tile-desktop.svg');
    }
  }

  &__container {
    @apply grid container mx-auto gap-x-10;
    grid-template-areas:
      "banner-text"
      "banner-button"
      "banner-image";

    @screen lg {
      grid-template-areas:
        "banner-text banner-image"
        "banner-button banner-image";
      grid-template-columns: 1fr 1fr;
    }
  }

  &__title {
    @apply font-semibold text-8xl leading-none text-brand-500 uppercase;
    grid-area: banner-text;

    @screen lg {
      @apply text-9xl self-end;
    }
  }

  &__subtitle {
    @apply block text-7xl leading-tighter text-base-100 mt-2;
  }

  &__description {
    @apply block font-sans font-normal text-3xl text-base-100 mt-5 normal-case;
  }

  &__cta-button {
    @apply uppercase border border-brand-500 py-3 px-5 text-4xl leading-none text-brand-500
    font-bold transition text-center mt-6;
    grid-area: banner-button;

    &:hover, &:focus {
      @apply bg-brand-500 text-black;
    };

    @screen lg {
      @apply self-start;
    }
  }

  &__video {
    @apply justify-self-center mt-20 mx-auto -mb-[100%];
    grid-area: banner-image;
    box-shadow:
      0 0 theme('spacing.4') theme('spacing.1') theme('colors.black'),
      0 0 theme('spacing.32') calc(theme('spacing.10') * -1) theme('colors.brand.500');

    @screen lg {
      @apply m-0 justify-self-end self-center;
    }
  }
}
