.polygon-banner {
    &__image {
      @apply mx-auto mb-24 h-10;
  
      @screen sm {
        @apply h-16;
      }
  
      @screen lg {
        @apply h-24;
      }
    }
  }
  